<template>
  <v-dialog v-model="dialog" persistent width="500">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Upload Data'" @close="dialog = false" />
      <v-card-text>
        <v-form :disabled="loading" ref="formData" v-model="valid" onSubmit="return false;">
          <!--          <v-text-field-->
          <!--            v-model="name"-->
          <!--            :rules="[rules.required]"-->
          <!--            dense-->
          <!--            label="Layer Name"-->
          <!--            outlined-->
          <!--            placeholder="Layer Name"-->
          <!--          ></v-text-field>-->
          <v-scale-transition>
            <div style="width: 100%; height: 175px">
              <DragBox :accept-types="acceptTypes" :files.sync="files" multiple />
            </div>
          </v-scale-transition>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn color="primary" rounded @click="createLayer" :loading="loading">
          Submit
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogHeader from '@/components/DialogHeader.vue'
import DragBox from '@/components/DragBox.vue'
import { uploadAOI } from '@/api/aoi-api'

export default {
  components: { DragBox, DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      name: '',
      vectorType: 'polygon',
      rules: {
        required: value => !!value || 'Name is required',
      },
      files: [],
      acceptTypes: '.zip,.geojson,.kml,.gml,.kmz,.shp,.shx,.prj,.dbf,.txt,.csv',
    }
  },
  watch: {
    dialog(val) {
      if (!val) {
        this.name = ''
        this.files = []
        this.$refs.formData.resetValidation()
      }
    },
  },
  methods: {
    createLayer() {
      this.$refs.formData.validate()
      if (!this.valid) return
      if (this.files.length) this.submitUpload()
      else {
        this.$emit('createLayer', this.name, this.vectorType)
        this.dialog = false
      }
    },
    async submitUpload() {
      try {
        this.loading = true
        const formData = new FormData()
        let totalItemOfShapeFile = 0
        let types = []
        for (let i = 0; i < this.files.length; i++) {
          let extension = this.files[i].name.split('.').pop()
          switch (extension.toLowerCase()) {
            case 'zip':
            case 'geojson':
            case 'kml':
            case 'kmz':
            case 'gml':
              formData.append('files[]', this.files[i])
              formData.append('type', extension.toLowerCase())
              types.push(extension.toLowerCase())
              break
            case 'shp':
            case 'prj':
            case 'shx':
            case 'dbf':
              totalItemOfShapeFile = totalItemOfShapeFile + 1
              formData.append('files', this.files[i])
              if (totalItemOfShapeFile === 1) formData.append('type', 'shp')
              types.push('shp')
              break
            case 'txt':
              formData.append('files', this.files[i])
              types.push(extension.toLowerCase())
              break
            case 'csv':
              formData.append('files', this.files[i])
              types.push(extension.toLowerCase())
              break
          }
        }
        formData.append('limit', -1)
        if ([...new Set(types)].length > 1)
          return this.$store.commit('message/SHOW_ERROR', 'The system does not support many file formats')
        if (types.includes('txt') || types.includes('csv')) {
          this.readTxtFile(this.files)
        } else {
          const res = await uploadAOI(formData)
          this.$emit('setLayer', res.data)
        }

        this.dialog = false
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    readTxtFile(files) {
      const reader = new FileReader()
      const vm = this
      reader.onload = function(event) {
        let lines = event.target.result.split('\n')
        lines =lines.filter(line => line.trim().length !== 0)
        const fieldNames = lines[0].split(',').map(fieldName => fieldName.trim().replace(/\s+/g, '_'))

        const data = lines.slice(1).map(line => {
          const columns = line.split(',')
          const row = {}
          for (let i = 0; i < fieldNames.length; i++) {
            row[fieldNames[i]] = columns[i]
          }
          return row
        })

        const fileExtension = files[0].name.split('.').pop()
        vm.$emit('setLayer', { data, fileExtension })
      }
      reader.onerror = function(event) {
        console.error('File could not be read! Code ' + event.target.error.code)
      }
      reader.readAsText(files[0])
    },
  },
}
</script>

<style scoped></style>
