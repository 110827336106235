<template>
  <v-layout class="fill-height pa-2 " column>
    <v-overlay :value="loading" absolute>
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <div class="pt-3" style="flex: none; width: 100%">
      <v-layout align-center>
        <div class="pr-2">Upload forest fire data</div>
        <v-divider />
        <v-btn
          fab
          @click="openUploadForm"
          class="ml-2"
          color="primary"
          small
          min-width="0"
          min-height="0"
          width="26"
          height="26"
        >
          <v-icon small>mdi-upload</v-icon>
        </v-btn>
      </v-layout>
    </div>
    <div class="pt-3" style="flex: none; width: 100%">
      <v-layout align-center>
        <div class="pr-2">Source</div>
        <v-divider />
      </v-layout>
    </div>
    <div class="px-2" style="flex: none; width: 100%">
      <v-radio-group v-model="source" class="mt-1 custom-radio-group" dense hide-details row>
        <v-radio :value="'modis'" label="MODIS"></v-radio>
        <v-radio :value="'viirs'" label="VIIRS"></v-radio>
        <v-radio :value="'himawari'" label="HIMAWARI"></v-radio>
      </v-radio-group>
    </div>
    <div ref="AOI">
      <div class="pt-3" style="flex: none; width: 100%">
        <v-layout align-center>
          <div class="pr-2">Place</div>
          <v-divider />
        </v-layout>
      </div>
      <div class="pt-2">
        <v-layout>
          <select2
            v-model="filter.aoi_id"
            :disabled="drawing || advSearch"
            :items="combinedList"
            :label="drawing ? 'AOI draw' : advSearch ? 'AOI Admin Boundary' : 'Select AOI'"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="uuid"
            outlined
            placeholder="Select AOI"
            @change="zoomToAOI(true)"
          />

          <!-- <v-menu offset-y>
            <template #activator="{ on: onMenu }">
              <v-tooltip bottom>
                <template #activator="{ on: onTooltip }">
                  <v-btn
                    :color="draw || drawing ? '#ffe600' : ''"
                    class="mt-2"
                    icon
                    small
                    v-on="{ ...onMenu, ...onTooltip }"
                  >
                    <v-icon>mdi-shape-polygon-plus</v-icon>
                  </v-btn>
                </template>
  
                <span>Draw AOI</span>
              </v-tooltip>
            </template>
            <v-list dense>
              <v-list-item dense @click="drawAOI">
                <v-list-item-title>
                  <v-icon :color="drawing ? '#ffe600' : ''" class="mr-2">mdi-shape-polygon-plus </v-icon>
                  Draw free AOI
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="filter.aoi_id" dense @click="drawInAOI">
                <v-list-item-title>
                  <v-icon :color="draw ? '#ffe600' : ''" class="mr-2">mdi-shape-polygon-plus </v-icon>
                  Draw in the selected AOI area
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu> -->
        </v-layout>
        <v-row align-center class="mt-1 mb-1">
          <v-col cols="6" class="d-flex align-center ">
            <div class="ml-1">Or create new AOI:</div>
          </v-col>
          <v-col cols="6" class="d-flex justify-end ">
            <v-btn small width="180" color="primary" @click="$refs.AOISelect.openDialog()">New AOI</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div ref="time">
      <div style="flex: none; width: 100%">
        <v-layout align-center>
          <div class="pr-2">Time</div>
          <v-divider />
        </v-layout>
      </div>
      <div class="pa-2" style="flex: none; width: 100%">
        <v-radio-group v-model="time" class="mt-1 custom-radio-group" dense hide-details label="" row>
          <v-radio :value="'24h'" class="mr-2" label="24h"></v-radio>
          <v-radio :value="'7days'" class="mr-2" label="Last 7 days"></v-radio>
          <v-radio :value="'custom'" class="mr-0" label="Custom"></v-radio>
        </v-radio-group>
      </div>
      <DateRangerPicker v-model="dateRange" :isPick.sync="time" dense label="Select date range" outlined />
    </div>
    <div ref="submit"  class="pt-3" style="flex: none; width: 100%">
      <v-btn :loading="loading" color="primary" small width="100%" @click="getHotspots">Get hotspots </v-btn>
    </div>
    <v-divider class="mt-4" />
    <div ref="result">
      <div class="pt-3" style="flex: none; width: 100%">
        <h4>Statistics</h4>
      </div>
      <div style="flex: none; width: 100%">
        <v-layout align-center>
          <div class="pa-2">Total: {{ data.length }}</div>
        </v-layout>
      </div>
      <div style="flex: none; width: 100%">
        <v-layout align-center class="px-6">
          <v-layout
            align-center
            column
            justify-center
            style="flex: 1; position: relative; cursor: pointer"
            @click="changeConfidence('l')"
          >
            <border-arrow v-if="confidence === 'l'" color="red" />
            <v-icon color="#FFCBC7" size="50">mdi-fire</v-icon>
            <div>{{ data.filter(val => val.confidence === 'l').length }}</div>
            <div>Low</div>
          </v-layout>
          <v-layout
            align-center
            column
            justify-center
            style="flex: 1; position: relative; cursor: pointer"
            @click="changeConfidence('n')"
          >
            <border-arrow v-if="confidence === 'n'" color="red" />
            <v-icon color="#FB8803" size="50">mdi-fire</v-icon>
            <div>{{ data.filter(val => val.confidence === 'n').length }}</div>
            <div>Moderate</div>
          </v-layout>
          <v-layout
            align-center
            column
            justify-center
            style="flex: 1; position: relative; cursor: pointer"
            @click="changeConfidence('h')"
          >
            <border-arrow v-if="confidence === 'h'" color="red" />
            <v-icon color="#FF1711" size="50">mdi-fire</v-icon>
            <div>{{ data.filter(val => val.confidence === 'h').length }}</div>
            <div>High</div>
          </v-layout>
        </v-layout>
      </div>
    </div>
    <UploadDataForestFire ref="uploadForm" @setLayer="setData" />
    <AOISelect
      @startDraw="drawAOI()"
      ref="AOISelect"
      @addAdminBoundary="() => $emit('addAdminBoundary')"
      @cancelGuide="() => $emit('continueGuide')"
    />
    <div class="d-flex" style="position: absolute; bottom: 10px; right: 0;">
      <v-spacer></v-spacer>
      <IconRegisterAlert eventRegister="forest_fire_alert" />
    </div>
  </v-layout>
</template>

<script>
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import { mapState } from '@/store/ults'
import DatePicker from '@/components/DatePicker.vue'
import { getListHotspots } from '@/api/hotspots-api'
import { getDetailAOI } from '@/api/aoi-api'
import BorderArrow from '@/components/BorderArrow.vue'
import UploadDataForestFire from '@/views/forest-fire/UploadDataForestFire.vue'
import Select2 from '@/components/Select2/Select2.vue'
import IconRegisterAlert from '@/components/notification/icon-register-alert.vue'
import AOISelect from './AOISelect.vue'
export default {
  components: {
    UploadDataForestFire,
    BorderArrow,
    DatePicker,
    DateRangerPicker,
    Select2,
    IconRegisterAlert,
    AOISelect,
  },
  data() {
    return {
      currentData: {},
      isNotification: false,
      confidence: undefined,
      loading: false,
      drawing: false,
      source: 'modis',
      time: '24h',
      dateRange: [],
      customTime: undefined,
      aoi: undefined,
      data: [],
      filter: {
        geojson: undefined,
        aoi_id: undefined,
        types: [],
      },
      draw: false,
    }
  },
  props: {
    advSearch: {
      type: Boolean,
      default: false,
    },
    isDraw: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
    },
    currentLayers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    combinedList() {
      if (this.currentLayers.length === 0) return this.listAOI
      const filteredCurrentLayers = {
        items: this.filterMapLayers(this.currentLayers),
        name: 'Map layers',
      }
      return [filteredCurrentLayers, ...this.listAOI]
    },
    withMapLayersAOIs() {
      if (this.currentLayers.length === 0) return this.AOIs
      const filteredCurrentLayersAoi = this.filterMapLayers(this.currentLayers)
      return [...filteredCurrentLayersAoi, ...this.AOIs]
    },
    notificationId() {
      return this.$route.query?.noti
    },
  },
  async mounted() {
    await this.initDataNoti()
  },
  watch: {
    tab(newVal, oldVal) {
      if (oldVal !== 0 && newVal !== 0) {
        this.time = '24h'
        this.dateRange = []
        this.source = 'modis'
        this.aoi = undefined
        this.filter.aoi_id = undefined
        if (this.$refs.adminBoundary) this.$refs.adminBoundary.reset()      }
    },
    isDraw(val) {
      this.drawing = val
    },
    confidence(value) {
      let geoJson = {
        type: 'FeatureCollection',
        features: [],
      }
      let availableData = value ? this.data.filter(val => val.confidence === value) : this.data
      availableData.forEach(point => {
        point.isFire = true
        geoJson.features.push({
          type: 'Feature',
          properties: point,
          geometry: {
            coordinates: JSON.parse(point.geojson).coordinates,
            type: 'Point',
          },
        })
      })
      this.$emit('addCusterToMap', geoJson)
    },
    async aoi(val) {
      this.$emit('resetDraw')
      if (val) {
        let featureCollection = await this.getDetailAOI(val)
        this.$emit('setDraw', featureCollection)
      }
    },
    filter: {
      handler(newValue) {
        this.$emit('continueGuide')
      },
      deep: true,
    },
    async notificationId() {
      await this.initDataNoti()
    },
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data?.order,
          aoi: noti.reference_data?.aoi,
          service: noti.reference_data?.service,
        }
        this.isNotification = true
      }
      this.getListAOI()
    },
    openUploadForm() {
      this.$refs.uploadForm.dialog = true
    },
    addGeojsonToMap(data) {
      if (!data.data.length) {
        this.$emit('resetDraw')
        return
      }
      let features = []
      data.data.forEach(geometry => {
        features.push({
          type: 'Feature',
          properties: {},
          geometry: geometry,
        })
      })

      let featureCollection = {
        type: 'FeatureCollection',
        features: features,
      }
      this.$emit('setDraw', featureCollection)
    },
    changeConfidence(val = undefined) {
      if (this.confidence !== val) this.confidence = val
      else this.confidence = undefined
    },
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    drawInAOI() {
      // this.$emit('resetDraw')
      this.draw = !this.draw
      this.drawing = false
      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else {
        this.$emit('removeAOI', this.selectedAOI)
        this.addToMap(aoi)
      }
      this.$emit('changeDrawTool', this.draw)
    },
    drawAOI() {
      if (this.selectedAOI) this.$emit('removeAOI', this.selectedAOI)
      this.draw = false
      this.currentAOIGeojson = undefined
      this.$emit('resetDraw')
      this.drawing = !this.drawing
      if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
      this.filter.aoi_id = undefined
      this.oldAOI = undefined
      this.$emit('changeDrawTool', this.drawing)
    },
    zoomToAOI(removeDraw = false) {
      if (this.selectedAOI) {
        // if (!this.currentLayers.map(layer => layer.uuid).includes(this.selectedAOI)) {
        //   this.$emit('removeAOI', this.selectedAOI)
        // }

        this.selectedAOI = this.filter.aoi_id
      }
      if (removeDraw) this.$emit('resetDraw')
      if (!this.filter.aoi_id) return
      this.selectedAOI = this.filter.aoi_id
      this.drawing = false
      // this.$emit('changeDrawTool', false)

      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else this.addToMap(aoi)
    },
    async addAOIGeojsonToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.$emit('addGeoJsonToMap', res)
        this.currentAOIGeojson = res
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        this.currentAOIGeojson = undefined
        let res
        if (this.currentLayers.map(layer => layer.id).includes(AOI.uuid)) {
          res = { geojson: AOI.geometry }
        } else {
          res = await this.getInfoAOI(AOI)
        }

        this.$emit('drawAOI', res.geojson)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        // if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
        // this.oldAOI = AOI.uuid
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: {},
        })
        if (this.isNotification) {
          this.filter.aoi_id = this.currentData.aoi.uuid
          this.zoomToAOI(true)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    filterMapLayers(layers) {
      return layers
        .filter(
          layer =>
            layer.type !== 'raster' &&
            (layer.geometry.features[0].geometry.type == 'Polygon' ||
              layer.geometry.features[0].geometry.type == 'MultiPolygon'),
        )
        .map(layer => {
          if (layer.geometry.features.length === 1) {
            let newLayer = { ...layer, uuid: layer.id }
            delete newLayer.id
            return newLayer
          }
          return layer
        })
    },
    setData(fileData) {
      this.data = []
      if (fileData) {
        if (fileData.fileExtension == 'txt' || fileData.fileExtension == 'csv') {
          this.data = fileData.data.map((val, index) => {
            let geojson = {
              type: 'Point',
              coordinates: [
                val.Longitude || val.longitude || val.Long || val.long || val.X || val.x,
                val.Latitude || val.latitude || val.Lat || val.lat || val.Y || val.y,
              ],
            }
            let confidence
            if (val.Confidence) {
              switch (val.Confidence.toLowerCase()) {
                case 'low':
                  confidence = 'l'
                  break
                case 'medium':
                  confidence = 'n'
                  break
                case 'high':
                  confidence = 'h'
                  break
                case '':
                  break
              }
            }
            delete val.Confidence
            return {
              ...val,
              isFire: true,
              isUpload: true,
              geojson: JSON.stringify(geojson),
              confidence: confidence,
            }
          })
          let geoJson = {
            type: 'FeatureCollection',
            features: [],
          }
          this.data.forEach(point => {
            point.isFire = true
            geoJson.features.push({
              type: 'Feature',
              properties: point,
              geometry: {
                coordinates: JSON.parse(point.geojson).coordinates,
                type: 'Point',
              },
            })
          })
          this.$emit('addCusterToMap', geoJson)
        } else {
          fileData.features.forEach(val => {
            val.properties['isFire'] = true
            val.properties['isUpload'] = true
          })
          this.data = fileData.features.map((val, index) => {
            return {
              isFire: val.properties.isFire,
              id: index,
              geojson: JSON.stringify(val.geometry),
            }
          })
          this.$emit('addCusterToMap', fileData)
        }
      }
    },
    async getHotspots() {
      try {
        this.loading = true
        this.confidence = undefined
        await this.$emit('getGeometry', 'hotspots')
        if (!this.filter.geojson.coordinates.length > 0) {
          this.$store.commit('message/SHOW_ERROR', 'AOI is required')
          return
        }

        let params = {
          type: this.time,
          source: this.source,
          geojson: this.filter.geojson,
        }
        if (this.time === 'custom') {
          params.from_date = this.dateRange[0]
          params.to_date = this.dateRange[1]
        }
        const res = await getListHotspots({ params: params })
        this.data = res.data
        let geoJson = {
          type: 'FeatureCollection',
          features: [],
        }
        this.data.forEach(point => {
          point.isFire = true
          geoJson.features.push({
            type: 'Feature',
            properties: point,
            geometry: {
              coordinates: JSON.parse(point.geojson).coordinates,
              type: 'Point',
            },
          })
        })
        this.$emit('addCusterToMap', geoJson)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    setCurrentData(data) {
      if (data?.aoi) {
        this.filter.aoi_id = data.aoi.uuid
      }
    },
  },
}
</script>

<style scoped></style>
