<template>
  <v-layout class="fill-height pa-2 overflow-y-auto overflow-x-hidden" column>
    <div class="pt-3" style="flex: none; width: 100%">
      <v-layout align-center>
        <div class="pr-2">Plate Boundaries</div>
        <v-divider />
      </v-layout>
    </div>
    <div class="px-2" style="flex: none; width: 100%">
      <v-checkbox v-model="displayBoundaries" hide-details label="Plate Boundaries"></v-checkbox>
    </div>
    <div ref="AOI">
      <div class="pt-3" style="flex: none; width: 100%">
        <v-layout align-center>
          <div class="pr-2">Place</div>
          <v-divider />
        </v-layout>
      </div>
      <div class="py-2">
        <v-layout>
          <select2
            v-model="filter.aoi_id"
            :disabled="drawing || advSearch"
            :items="combinedList"
            :label="drawing ? 'AOI draw' : advSearch ? 'AOI Admin Boundary' : 'Select AOI'"
            clearable
            dense
            hide-details
            item-text="name"
            item-value="uuid"
            outlined
            placeholder="Select AOI"
            @change="zoomToAOI(true)"
          />
        </v-layout>
        <v-row align-center class="mt-1 mb-1">
          <v-col cols="6" class="d-flex align-center">
            <div class="ml-1">Or create new AOI:</div>
          </v-col>
          <v-col cols="6" class="d-flex justify-end">
            <v-btn small width="180" color="primary" @click="$refs.AOISelect.openDialog()">New AOI</v-btn>
          </v-col>
        </v-row>
      </div>
    </div>
    <div ref="time">
      <div class="pt-3" style="flex: none; width: 100%">
        <v-layout align-center>
          <div class="pr-2">Time</div>
          <v-divider />
        </v-layout>
      </div>
      <div class="pa-2" style="flex: none; width: 100%">
        <v-radio-group v-model="time" class="mt-1 custom-radio-group" dense hide-details label="" row>
          <v-radio :value="'24h'" class="mr-2" label="24h"></v-radio>
          <v-radio :value="'7days'" class="mr-2" label="Last 7 days"></v-radio>
          <v-radio :value="'custom'" class="mr-0" label="Custom"></v-radio>
        </v-radio-group>
      </div>
      <div class="pt-3 px-2 mb-2" style="flex: none; width: 100%">
        <DateRangerPicker v-model="dateRange" :isPick.sync="time" dense label="Select date range" outlined />
      </div>
    </div>
    <div ref="submit" class="pt-3" style="flex: none; width: 100%">
      <v-btn :loading="loading" color="primary" small width="100%" @click="getEarthquake">Search </v-btn>
    </div>
    <!--    <div style="flex: none; width: 100%" class="pt-3">-->
    <!--      <v-btn :loading="loading" small width="100%" color="primary" @click="() => $emit('exportPDF')"-->
    <!--        >Get hotspots-->
    <!--      </v-btn>-->
    <!--    </div>-->
    <v-divider class="mt-4" />
    <div class="pt-3" style="flex: none; width: 100%">
      <h4>Statistics</h4>
    </div>
    <div class="pa-2" style="flex: none; width: 100%">
      <div>Magnitude</div>
    </div>
    <div class="color-bar">
      <div
        v-for="(group, index) in listGroup"
        :key="index"
        class="d-flex justify-center align-center"
        :style="{ backgroundColor: group.color, flex: 1, color: 'black' }"
      >
        <span>{{ group.property[0] === '<' ? group.property[2] - 1 : group.property[2] + '+' }}</span>
      </div>
    </div>
    <div style="flex: none; width: 100%">
      <v-layout align-center>
        <div class="pa-2">Total: {{ data && data.features ? data.features.length : 0 }}</div>
      </v-layout>
    </div>

    <div class="legend"></div>
    <div class="list-legend">
      <div v-for="(item, index) in listGroup" :key="index" class="d-flex">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-layout @click="selectGroup(index)" align-center class="legend-item" v-bind="attrs" v-on="on">
              <div
                class="circle"
                :style="{ backgroundColor: item.color, width: `${10 + index * 2}px`, height: `${10 + index * 2}px` }"
              ></div>
              <div class="pr-2">
                {{ filterData(item, index) ? filterData(item, index).length : 0 }}
              </div>
              <border-arrow v-if="selectedGroup == index" color="red" />
            </v-layout>
          </template>
          <span>{{ getRange(index) }}</span>
        </v-tooltip>
      </div>
    </div>
    <AOISelect
      @startDraw="drawAOI()"
      ref="AOISelect"
      @addAdminBoundary="() => $emit('addAdminBoundary')"
      @cancelGuide="() => $emit('continueGuide')"
    />
    <div class="d-flex" style="position: absolute; bottom: 10px; right: 0">
      <v-spacer></v-spacer>
      <IconRegisterAlert eventRegister="earthquake_alert" />
    </div>
  </v-layout>
</template>

<script>
import DateRangerPicker from '@/components/DateRangerPicker.vue'
import { mapState } from '@/store/ults'
import DatePicker from '@/components/DatePicker.vue'
import { getEarthquake, getListHotspots } from '@/api/hotspots-api'
import { getDetailAOI } from '@/api/aoi-api'
import BorderArrow from '@/components/BorderArrow.vue'
import Select2 from '@/components/Select2/Select2.vue'
import IconRegisterAlert from '@/components/notification/icon-register-alert.vue'
import AOISelect from './AOISelect.vue'

export default {
  components: { BorderArrow, DatePicker, DateRangerPicker, Select2, IconRegisterAlert, AOISelect },
  data() {
    return {
      currentData: {},
      isNotification: false,
      confidence: undefined,
      loading: false,
      drawing: false,
      advSearch: false,
      time: '24h',
      dateRange: [],
      customTime: undefined,
      aoi: undefined,
      data: [],
      filter: {
        geojson: undefined,
        aoi_id: undefined,
        types: [],
      },
      draw: false,

      listGroup: [
        {
          property: ['<', ['get', 'mag'], '1'],
          color: '#f9ff8e',
        },
        {
          property: ['<', ['get', 'mag'], '2'],
          color: '#ffc500',
        },
        {
          property: ['<', ['get', 'mag'], '3'],
          color: '#ff9a50',
        },
        {
          property: ['<', ['get', 'mag'], '4'],
          color: '#ff7070',
        },
        {
          property: ['<', ['get', 'mag'], '5'],
          color: '#ff7950',
        },
        {
          property: ['<', ['get', 'mag'], '6'],
          color: '#ff5f00',
        },
        {
          property: ['<', ['get', 'mag'], '7'],
          color: '#fd0069',
        },
        {
          color: '#FF0000',
          property: ['>', ['get', 'mag'], '7'],
        },
      ],
      displayBoundaries: false,
      selectedGroup: -1,
    }
  },
  props: {
    isDraw: {
      type: Boolean,
      default: false,
    },
    tab: {
      type: Number,
    },
    currentLayers: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    ...mapState('AOI', ['AOIs', 'listAOI']),
    combinedList() {
      if (this.currentLayers.length === 0) return this.listAOI
      const filteredCurrentLayers = {
        items: this.filterMapLayers(this.currentLayers),
        name: 'Map layers',
      }
      return [filteredCurrentLayers, ...this.listAOI]
    },
    withMapLayersAOIs() {
      if (this.currentLayers.length === 0) return this.AOIs
      const filteredCurrentLayersAoi = this.filterMapLayers(this.currentLayers)
      return [...filteredCurrentLayersAoi, ...this.AOIs]
    },
    notificationId() {
      return this.$route.query?.noti
    },
  },
  async mounted() {
    await this.initDataNoti()
    // this.addBoundaries()
  },
  watch: {
    async notificationId() {
      await this.initDataNoti()
    },
    displayBoundaries(val) {
      const boundaries = this.currentLayers.find(layer => layer.dataId === 'earthquake_boundaries')

      if (val) {
        if (boundaries) {
          this.updateBoundaries(boundaries, true)
        } else {
          this.addNewBoundaries()
        }
      } else if (boundaries) {
        this.updateBoundaries(boundaries, false)
      }
    },
    currentLayers: {
      handler() {
        let boundaries = this.currentLayers.find(layer => layer.dataId === 'earthquake_boundaries')
        if (!boundaries || !boundaries.layerRight) this.displayBoundaries = false
        else this.displayBoundaries = true
      },
      deep: true,
    },
    tab(newVal, oldVal) {
      if (oldVal !== 0 && newVal === 1) {
        this.time = '24h'
        this.dateRange = []
        this.aoi = undefined
        this.filter.aoi_id = undefined
        if (this.$refs.adminBoundary) this.$refs.adminBoundary.reset()
      }
    },
    isDraw(val) {
      this.drawing = val
    },
    confidence(value) {
      let geoJson = {
        type: 'FeatureCollection',
        features: [],
      }
      let availableData = value ? this.data.filter(val => val.confidence === value) : this.data
      availableData.forEach(point => {
        point.isFire = true
        geoJson.features.push({
          type: 'Feature',
          properties: point,
          geometry: {
            coordinates: JSON.parse(point.geojson).coordinates,
            type: 'Point',
          },
        })
      })
      this.$emit('addCusterToMap', geoJson, this.source)
    },
    selectedGroup(value) {
      if (this.data && this.data.features) {
        let availableFeatures = value !== -1 ? this.filterData(this.listGroup[value], value) : this.data.features
        let availableData = {
          ...this.data,
          features: availableFeatures,
        }

        this.$emit('addCusterToMap', availableData)
      }
    },
    filter: {
      handler(newValue) {
        this.$emit('continueGuide')
      },
      deep: true,
    },
    async aoi(val) {
      this.$emit('resetDraw')
      if (val) {
        let featureCollection = await this.getDetailAOI(val)
        this.$emit('setDraw', featureCollection)
      }
    },
  },
  methods: {
    async initDataNoti() {
      const query = this.$route.query
      if (query.notification && this.notificationId) {
        const noti = await this.$store.dispatch('notification/getNotification', query.noti)
        this.currentData = {
          ...noti.reference_data?.order,
          aoi: noti.reference_data?.aoi,
          service: noti.reference_data?.service,
        }
        this.isNotification = true
      }
      this.getListAOI()
    },
    getRange(index) {
      if (index === 0) return '0 -' + this.listGroup[index].property[2]
      if (index === this.listGroup.length - 1) return `${this.listGroup[index].property[2]}+`
      return `${this.listGroup[index - 1].property[2]} - ${this.listGroup[index].property[2]}`
    },
    updateBoundaries(boundaries, visible) {
      boundaries.layerRight = visible
      const visibility = visible ? 'visible' : 'none'
      this.$emit('changeVisible', 'earthquake_boundaries', visibility)
    },

    addNewBoundaries() {
      this.$emit('addBoundaries', {
        id: 'earthquake_boundaries',
        display: this.displayBoundaries,
        data: {
          tile_url: 'https://earthquake.usgs.gov/basemap/tiles/plates/{z}/{x}/{y}.png',
          bbox: [-180.0, -85.0511, 180.0, 85.0511],
        },
        layerType: 'Raster_Image',
        name: 'earthquake_boundaries',
        tiles: ['https://earthquake.usgs.gov/basemap/tiles/plates/{z}/{x}/{y}.png'],
        bounds: [-180.0, -85.0511, 180.0, 85.0511],
      })
    },
    addGeojsonToMap(data) {
      if (!data.data.length) {
        this.$emit('resetDraw')
        return
      }
      let features = []
      data.data.forEach(geometry => {
        features.push({
          type: 'Feature',
          properties: {},
          geometry: geometry,
        })
      })

      let featureCollection = {
        type: 'FeatureCollection',
        features: features,
      }
      this.$emit('setDraw', featureCollection)
    },
    async getDetailAOI(AOI) {
      try {
        this.loading = true
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data.geojson
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    drawInAOI() {
      this.$emit('resetDraw')
      this.draw = !this.draw
      this.drawing = false
      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else {
        this.$emit('removeAOI', this.selectedAOI)

        this.addToMap(aoi)
      }
      this.$emit('changeDrawTool', this.draw)
    },
    drawAOI() {
      if (this.selectedAOI) this.$emit('removeAOI', this.selectedAOI)
      this.draw = false
      this.currentAOIGeojson = undefined
      this.$emit('resetDraw')
      this.drawing = !this.drawing
      if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
      this.filter.aoi_id = undefined
      this.oldAOI = undefined
      this.$emit('changeDrawTool', this.drawing)
    },
    zoomToAOI(removeDraw = false) {
      if (this.selectedAOI) {
        // if (!this.currentLayers.map(layer => layer.uuid).includes(this.selectedAOI)) {
        //   this.$emit('removeAOI', this.selectedAOI)
        // }

        this.selectedAOI = this.filter.aoi_id
      }
      if (removeDraw) this.$emit('resetDraw')
      if (!this.filter.aoi_id) return
      this.selectedAOI = this.filter.aoi_id
      this.drawing = false
      // this.$emit('changeDrawTool', false)

      let aoi = this.withMapLayersAOIs.find(val => val.uuid === this.filter.aoi_id)
      if (!aoi) return
      if (aoi.planet_key) PlanetApi.setApikey(aoi.planet_key)
      this.$emit('zoomTo', aoi.bbox)
      if (this.draw) this.addAOIGeojsonToMap(aoi)
      else this.addToMap(aoi)
    },
    async addAOIGeojsonToMap(AOI) {
      try {
        this.loading = true
        const res = await this.getInfoAOI(AOI)
        this.$emit('addGeoJsonToMap', res)
        this.currentAOIGeojson = res
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async addToMap(AOI) {
      try {
        this.loading = true
        this.currentAOIGeojson = undefined
        let res
        if (this.currentLayers.map(layer => layer.id).includes(AOI.uuid)) {
          res = { geojson: AOI.geometry }
        } else {
          res = await this.getInfoAOI(AOI)
        }

        this.$emit('drawAOI', res.geojson)
        AOI.display = true
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    async getInfoAOI(AOI) {
      try {
        this.loading = true
        // if (this.oldAOI) this.$emit('removeAOI', this.oldAOI)
        // this.oldAOI = AOI.uuid
        let res = await getDetailAOI({ projectId: this.$route.params.id, id: AOI.uuid })
        res.data.geojson = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: res.data.geojson,
            },
          ],
        }
        return res.data
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async getListAOI() {
      try {
        this.loading = true
        await this.$store.dispatch('AOI/getListAOI', {
          projectId: this.$route.params.id,
          payload: {},
        })
        if (this.isNotification) {
          this.filter.aoi_id = this.currentData.aoi.uuid
          this.zoomToAOI(true)
        }
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    filterMapLayers(layers) {
      return layers
        .filter(
          layer =>
            layer.type !== 'raster' &&
            (layer.geometry.features[0].geometry.type == 'Polygon' ||
              layer.geometry.features[0].geometry.type == 'MultiPolygon'),
        )
        .map(layer => {
          if (layer.geometry.features.length === 1) {
            let newLayer = { ...layer, uuid: layer.id }
            delete newLayer.id
            return newLayer
          }
          return layer
        })
    },
    createList(data) {
      const list = []
      for (let i = 1; i < data.length; i += 2) {
        const color = data[i + 1] || data[i]
        const property = color == data[i] ? ['>', ['get', 'mag'], Number(data[i - 2][2])] : data[i]
        list.push({ property, color })
      }
      return list
    },
    filterData(item, index) {
      if (this.data && this.data.features) {
        const previous = index > 0 ? Number(this.listGroup[index - 1].property[2]) : 0
        const current = Number(item.property[2])

        if (isNaN(current)) {
          return this.data.features.filter(feature => feature.properties.mag > previous)
        } else {
          return this.data.features.filter(
            feature => feature.properties.mag < current && feature.properties.mag >= previous,
          )
        }
      }
    },
    selectGroup(index) {
      if (this.selectedGroup === index) {
        this.selectedGroup = -1
      } else {
        this.selectedGroup = index
      }
    },
    async getEarthquake() {
      try {
        this.loading = true
        this.confidence = undefined
        await this.$emit('getGeometry', 'earthquake')
        if (!this.filter.geojson.coordinates.length > 0) {
          this.$store.commit('message/SHOW_ERROR', 'AOI is required')
          return
        }
        let params = {
          type: this.time,
          geojson: this.filter.geojson,
        }

        if (this.time === 'custom') {
          params.from_date = this.dateRange[0]
          params.to_date = this.dateRange[1]
        }
        const res = await getEarthquake({ params: params })
        this.data = await res.data
        // let geoJson = {
        //   type: 'FeatureCollection',
        //   features: [],
        // }
        // this.data.forEach(point => {
        //   point.isFire = false
        //   geoJson.features.push({
        //     type: 'Feature',
        //     properties: point,
        //     geometry: {
        //       coordinates: JSON.parse(point.geojson).coordinates,
        //       type: 'Point',
        //     },
        //   })
        // })

        this.data.style = {
          'circle-color': [
            'case',
            ['<', ['get', 'mag'], '1'],
            '#f9ff8e',
            ['<', ['get', 'mag'], '2'],
            '#ffc500',
            ['<', ['get', 'mag'], '3'],
            '#ff9a50',
            ['<', ['get', 'mag'], '4'],
            '#ff7070',
            ['<', ['get', 'mag'], '5'],
            '#ff7950',
            ['<', ['get', 'mag'], '6'],
            '#ff5f00',
            ['<', ['get', 'mag'], '7'],
            '#fd0069',
            '#FF0000',
          ],
          'circle-radius': [
            'case',
            ['<', ['get', 'mag'], '1'],
            2,
            ['<', ['get', 'mag'], '2'],
            3,
            ['<', ['get', 'mag'], '3'],
            4,
            ['<', ['get', 'mag'], '4'],
            6,
            ['<', ['get', 'mag'], '5'],
            8,
            ['<', ['get', 'mag'], '6'],
            10,
            ['<', ['get', 'mag'], '7'],
            12,
            15,
          ],
          'circle-stroke-width': ['case', ['<', ['get', 'mag'], '1'], 1, ['<', ['get', 'mag'], '2'], 2, 3],
          'circle-stroke-color': '#fffdfd',
        }
        this.listGroup = this.createList(this.data.style['circle-color'])

        this.$emit('addCusterToMap', this.data)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    setCurrentData(data) {
      if (data?.aoi) {
        this.filter.aoi_id = data.aoi.uuid
      }
    },
  },
}
</script>

<style scoped>
.circle {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin: 5px;
  background-color: #000;
  border: 3px solid #fffdfd;
}
.legend-item {
  padding: 5px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  position: relative;
  cursor: pointer;
  flex-wrap: wrap;
}
.list-legend {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  padding: 0 5px;
  margin: 5px;
}
.color-bar {
  padding: 0 7px;
  display: flex;
  height: 20px;
  width: 100%;
}
</style>
