<template>
  <div class="fill-height" column style="width: 100%">
    <v-layout ref="forestFire" class="fill-height">
      <v-card v-show="leftMenu" :loading="loading" class="AOI-menu custom-card-bg-1 mr-4" height="100%" width="340">
        <v-btn class="map-overview-menu" color="bgContainer" fab @click="leftMenu = false">
          <v-icon size="26">icon-chevrons_left</v-icon>
        </v-btn>
        <v-tabs
          v-model="tab"
          fixed-tabs
          height="48"
          style="max-height: 48px; border-top-left-radius: 8px; border-top-right-radius: 8px"
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab class="ml-1 custom-tab-name"> Layers </v-tab>
          <v-tab v-if="$route.name === 'forest-fire'" class="ml-0 custom-tab-name"> Hotspots </v-tab>
          <v-tab v-if="$route.name === 'earthquake'" class="ml-0 custom-tab-name"> Earthquake </v-tab>
          <v-tab class="ml-0 custom-tab-name"> Discovery </v-tab>
          <v-spacer />
          <v-divider vertical />
          <v-btn
            class="top-menu-map-overview"
            color="primary"
            min-height="0"
            min-width="0"
            style="height: 100% !important"
            text
            title="Hide"
            width="20"
            @click="leftMenu = false"
          >
            <v-icon size="26">mdi-menu-down</v-icon>
          </v-btn>
        </v-tabs>
        <v-divider />
        <v-tabs-items
          v-model="tab"
          class="custom-menu-tab"
          style="height: calc(100% - 50px); background-color: transparent"
        >
          <v-tab-item style="height: 100%">
            <v-card class="custom-card-bg-0 py-t" flat height="100%">
              <LayerControl
                ref="layerControl"
                :current-layers.sync="layers"
                :isDrawing.sync="isDraw"
                comparable
                map-control
                removable
                transparent
                @setLayer="setLayer"
                @cancel="cancelCreate"
                @changeCompare="show => $refs.map.changeCompare(show)"
                @changeToSyncMap="status => ($refs.map.mapSync = status)"
                @changeVisible="layer => $refs.map.changeVisibility(layer.id)"
                @changeVisibleLeftLayer="(layer, isSync) => $refs.map.changeVisibleLeftLayer(layer.id)"
                @createLayer="createLayer"
                @reloadMap="() => $refs.map.reloadMap()"
                @removeAllLayer="() => $refs.map.removeAllLayer()"
                @removeLayer="layerId => $refs.map.removeLayer(layerId)"
                @saveLayer="saveLayer"
                @updateStyleProperties="layerId => $refs.map.updateStyleProperties(layerId)"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$route.name === 'forest-fire'" style="height: 100%">
            <v-card class="custom-card-bg-0 py-2" flat height="100%">
              <Hotspots
                v-show="tab === 1"
                ref="hotspots"
                :isDraw="isDraw"
                :tab="tab"
                @drawAOI="aoi => $refs.map.drawAOI(aoi)"
                :current-layers.sync="layers"
                @addCusterToMap="addClusterToMap"
                @removeAOI="id => $refs.map.removeLayer(id)"
                @changeDrawTool="changeDrawTool"
                @getGeometry="getGeometry"
                @resetDraw="() => $refs.map.resetDraw()"
                @setDraw="featureCollection => $refs.map.drawAOI(featureCollection)"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
                @continueGuide="() => $refs.tourGuide.cancelTour()"
                @addAdminBoundary="
                  () => {
                    isAdminBoundary = true
                    resetDraw()
                  }
                "
                :advSearch="isAdminBoundary"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="$route.name === 'earthquake'" style="height: 100%">
            <v-card class="custom-card-bg-0 py-2" flat height="100%">
              <Earthquake
                ref="earthquake"
                :isDraw="isDraw"
                :tab="tab"
                @drawAOI="aoi => $refs.map.drawAOI(aoi)"
                :current-layers.sync="layers"
                @addBoundaries="image => $refs.map.addImageToMap(image)"
                @addCusterToMap="addPointToMap"
                @changeDrawTool="changeDrawTool"
                @changeVisible="changeDisplayLayer"
                @getGeometry="getGeometry"
                @removeAOI="id => $refs.map.removeLayer(id)"
                @resetDraw="() => $refs.map.resetDraw()"
                @setDraw="featureCollection => $refs.map.drawAOI(featureCollection)"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
                @continueGuide="() => $refs.tourGuide.cancelTour()"
                @addAdminBoundary="
                  () => {
                    isAdminBoundary = true
                    resetDraw()
                  }
                "
                :advSearch="isAdminBoundary"
              />
            </v-card>
          </v-tab-item>
          <v-tab-item style="height: 100%">
            <v-card class="custom-card-bg-0" flat height="100%" v-show="tab === 2">
              <ImageCoverage
                ref="daily"
                :tab="tab"
                :current-layers.sync="layers"
                @addGeoJsonToMap="addGeoJsonToMap"
                @addThumbnailToMap="addThumbnailToMap"
                @changeDrawTool="changeDrawTool"
                @disableDraw="() => $refs.map.disableEditDraw()"
                @drawAOI="aoi => $refs.map.drawAOI(aoi)"
                @getGeometry="getGeometry"
                @removeAOI="id => $refs.map.removeLayer(id)"
                @removeAllLayer="() => $refs.map.removeAllLayer()"
                @removeLayer="removeData"
                @removeSearchLayer="removeSearchLayer"
                @resetDraw="() => $refs.map.resetDraw()"
                @zoomTo="bbox => $refs.map.submitZoom(bbox)"
                :isDraw="isDraw"
                @addAdminBoundary="
                  () => {
                    isAdminBoundary = true
                    resetDraw()
                  }
                "
                :advSearch="isAdminBoundary"
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <v-layout class="fill-height">
        <v-card height="100%" style="position: relative" width="100%">
          <v-btn
            v-if="!leftMenu"
            color="bgContainer"
            fab
            style="position: absolute; bottom: 12px; left: 12px; z-index: 2"
            @click="leftMenu = true"
          >
            <v-icon>icon-layers</v-icon>
          </v-btn>
          <Map
            ref="map"
            :current-layers.sync="layers"
            :drawType.sync="drawType"
            :is-draw.sync="isDraw"
            :isCreateText="displayType === 'text'"
            @featureInfo="displayPopup"
            @resetDraw="removeDraw"
          />
          <TourGuide
            style="position: absolute; bottom: 40px; right: 125px; z-index: 2"
            ref="tourGuide"
            :color="'#e3e3e3'"
            :iconColor="'#66808d'"
            v-if="elements"
            :elements="elements"
          />
          <v-card
            v-if="isAdminBoundary"
            style="position: absolute; top: 10px; left: 10px; width: 400px"
            class="pa-3 custom-card-bg-2"
          >
            <v-row class="ma-1">
              <h2 class="mb-2">Admin Boundary</h2>
              <v-spacer></v-spacer>
              <v-icon @click="isAdminBoundary = false">mdi-close</v-icon>
            </v-row>
            <AdministratorBoundary ref="adminBoundary" @geometry="addAdminBoundary" />
          </v-card>
        </v-card>
      </v-layout>
    </v-layout>
    <BasicCreateForm ref="createLayer" @submitSave="saveNewLayer" />
    <CreateOrder ref="createOrder" />
  </div>
</template>

<script>
import Map from '@/components/Map'
import LayerControl from '@/components/layer-control/LayerControl.vue'
import bbox from '@turf/bbox'
import { getDataExplorer } from '@/api/explorer-api'
import VectorStyle from '@/utils/VectorStyle'
import Hotspots from '@/views/forest-fire/Hotspots.vue'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import html2canvas from 'html2canvas'
import ImageCoverage from '@/components/imagery-coverage/ImageryCoverage.vue'
import sleep from '@/utils/sleep'
import { getDetailEarthquake, getDetailHotspots } from '@/api/hotspots-api'
import utils from '@/utils/genUUID'
import CreateOrder from '@/views/createOrder/CreateOrder.vue'
import Earthquake from '@/views/forest-fire/Earthquake.vue'
import BasicCreateForm from '@/components/BasicCreateForm.vue'
import { mapState } from '@/store/ults'
import AdministratorBoundary from '@/components/AdministratorSearch.vue'
import TourGuide from '@/components/GuideTour/index.vue'
// import DataExplorerModel from '@/models/data/data-explorer'
import config from '@/config'

export default {
  name: 'MapView',
  components: {
    BasicCreateForm,
    Earthquake,
    CreateOrder,
    Hotspots,
    ImageCoverage,
    LayerControl,
    Map,
    AdministratorBoundary,
    TourGuide,
  },
  data() {
    return {
      isDraw: false,
      loading: false,
      tab: 1,
      layers: [],
      oldLayers: [],
      searchLayers: [],
      leftMenu: true,
      allData: [],
      layerName: '',
      drawType: '',
      displayType: '',
      isAdminBoundary: false,
      elements: undefined,
    }
  },
  computed: { ...mapState('auth', ['currentUser']) },
  async mounted() {
    const query = this.$route.query
    if (query.notification) {
      const currentData = this.$store.state.notification.currentQuery
      const queryName = this.$route.name
      if (queryName === 'forest-fire') {
        this.$refs.hotspots.setCurrentData(currentData)
      } else if (queryName === 'earthquake') {
        this.$refs.earthquake.setCurrentData(currentData)
      }
    }
    this.$nextTick(() => {
      if (this.$route.name == 'forest-fire')
        this.elements = [
          {
            ref: this.$refs.hotspots.$refs.AOI,
            id: 'AOI',
            title: 'Getting Started',
            text: 'Select an AOI to get results',
            position: 'right',
          },
          {
            ref: this.$refs.hotspots.$refs.time,
            id: 'time',
            title: 'Getting Started',
            text: 'Select time to search result',
            position: 'right',
          },
          {
            ref: this.$refs.hotspots.$refs.submit,
            id: 'submit',
            title: 'Getting Started',
            text: 'Click to get hotspot',
            position: 'right',
          },
        ]
      else
        this.elements = [
          {
            ref: this.$refs.earthquake.$refs.AOI,
            id: 'AOI',
            title: 'Getting Started',
            text: 'Select an AOI to get results',
            position: 'right',
          },
          {
            ref: this.$refs.earthquake.$refs.time,
            id: 'time',
            title: 'Getting Started',
            text: 'Select time to search result',
            position: 'right',
          },
          {
            ref: this.$refs.earthquake.$refs.submit,
            id: 'submit',
            title: 'Getting Started',
            text: 'Click to search earthquake',
            position: 'right',
          },
        ]
    })
    await sleep(500)
    if (this.$refs.earthquake) this.$refs.earthquake.displayBoundaries = true
  },
  watch: {
    tab(newVal, oldVal) {
      this.drawType = ''
      this.displayType = ''
      switch (newVal) {
        case 0:
          this.resetDraw()
          break
        case 1:
          // this.getDataExplorer()
          break
        case 2:
          if (this.$refs.layerControl) this.$refs.layerControl.changeMapDisplay('overlay')
          // let layerWithoutSearch = this.layers.filter(
          //   layer => !this.searchLayers.some(image => image.id === layer.dataId),
          // )
          // layerWithoutSearch.forEach(layer => {
          //   this.$refs.map.changeVisibility(layer.id, 'none')
          // })
          if (this.$refs.daily) this.$refs.daily.zoomToAOI()
          this.getAOI()
          break
        default:
          this.resetDraw()
          this.$refs.map.reloadMap()
      }
    },
    layers() {
      let removedLayer = []
      this.layers.forEach(layer => {
        layer.group = 'Map layers'
      })

      this.searchLayers.forEach(image => {
        if (!this.layers.some(layer => layer.dataId === image.id)) {
          removedLayer.push(image)
        }
      })
      removedLayer.forEach(layer => {
        let index = this.searchLayers.findIndex(image => image.id === layer.id)
        if (index >= 0) this.searchLayers.splice(index, 1)
      })
    },
    leftMenu() {
      this.$refs.map.reSize()
    },
  },

  methods: {
    addAdminBoundary(data) {
      this.isDraw = false
      if (this.$refs.hotspots) this.$refs.hotspots.addGeojsonToMap(data)
      if (this.$refs.earthquake) this.$refs.earthquake.addGeojsonToMap(data)
      if (this.$refs.daily) this.$refs.daily.addGeojsonToMap(data)
    },
    setLayer(name, geojson) {
      this.$refs.map.addGeoJsonToMap(name, geojson, '#e2ed09', utils.getUUID(), 'line', true)
    },
    openSaveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      if (!features.length) return this.$store.commit('message/SHOW_ERROR', 'Feature is required')
      this.$refs.createLayer.openDialog()
    },
    saveNewLayer(name) {
      this.layerName = name
      this.displayType = 'line'
      this.saveLayer()
    },
    cancelCreate() {
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    saveLayer() {
      let features = this.$refs.map.getAllDrawFeature()
      if (!features.length) return this.$store.commit('message/SHOW_ERROR', 'Feature is required')
      let vectorType
      switch (this.displayType) {
        case 'polygon':
        case 'line':
          vectorType = 'line'
          break
        case 'point':
          vectorType = 'circle'
          break
      }
      if (this.displayType === 'marker') {
        features.forEach(feature => {
          feature.properties = {}
          for (let i = 0; i < feature.geometry.coordinates.length; i++) {
            feature.geometry.coordinates[i] = feature.geometry.coordinates[i].toFixed(4)
          }
          feature.properties.text = feature.geometry.coordinates.toString().replaceAll(',', ', ')
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addMarkerSymbolToMap(this.layerName, featureCollection, 'yellow')
      } else if (this.displayType === 'text') {
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addTextToMap(this.layerName, featureCollection, 'yellow')
      } else {
        features.forEach(feature => {
          feature.properties = {}
          feature.properties.text = feature.geometry.coordinates.toString()
        })
        let featureCollection = {
          type: 'FeatureCollection',
          features: features,
        }
        this.$refs.map.addGeoJsonToMap(this.layerName, featureCollection, 'yellow', utils.getUUID(), vectorType, true)
      }
      this.drawType = undefined
      this.layerName = ''
      this.$refs.map.deleteAllDraw()
      this.isDraw = false
    },
    createLayer(name, type) {
      this.displayType = type
      this.layerName = name
      this.$refs.map.createLayer(name, type)
      switch (type) {
        case 'polygon':
        case 'line':
          this.drawType = 'polygon'
          break
        case 'marker':
        case 'text':
          this.drawType = 'point'
          break
      }
      this.isDraw = true
    },
    removeDraw() {
      this.$refs.map.resetDraw()
      if (this.$refs.daily) {
        this.$refs.daily.filter.aoi_id = undefined
      }
      if (this.$refs.hotspots) {
        this.$refs.hotspots.aoi = undefined
      }
      if (this.$refs.earthquake) {
        this.$refs.earthquake.aoi = undefined
      }
    },
    resetDraw() {
      // this.$refs.map.resetDraw()
      if (this.$refs.daily) {
        this.$refs.daily.filter.aoi_id = undefined
        this.$refs.daily.drawing = false
      }
      if (this.$refs.hotspots) {
        this.$refs.hotspots.aoi = undefined
        this.$refs.hotspots.drawing = false
      }
      if (this.$refs.earthquake) {
        this.$refs.earthquake.aoi = undefined
        this.$refs.earthquake.drawing = false
      }
      this.isDraw = false
    },
    getGeometry(ref = 'daily') {
      let features = this.$refs.map.getAllDrawFeature()
      let tmpGeometry = {
        coordinates: [],
        type: 'MultiPolygon',
      }
      // let multiPolygon = features.find(val => val.geometry.type === 'MultiPolygon')
      // tmpGeometry.coordinates = multiPolygon ? multiPolygon.geometry.coordinates : []
      features.forEach(feature => {
        if (feature.geometry.type !== 'MultiPolygon') {
          tmpGeometry.coordinates.push(feature.geometry.coordinates)
        } else {
          tmpGeometry.coordinates = tmpGeometry.coordinates.concat(feature.geometry.coordinates)
        }
      })
      this.$refs[ref].filter.geojson = tmpGeometry
    },
    changeDrawTool(status) {
      this.isDraw = status
      if (this.isDraw) this.isAdminBoundary = false
    },
    exportToPDF() {
      pdfMake.vfs = pdfFonts.pdfMake.vfs
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      // Use html2canvas to render HTML onto the canvas
      html2canvas(this.$refs.forestFire).then(function (canvas) {
        ctx.drawImage(canvas, 0, 0)
        const fileURL = canvas.toDataURL()

        // Create a temporary link element
        const link = document.createElement('a')
        link.href = fileURL
        link.download = 'file.png'

        // Append the link to the DOM and trigger the download
        document.body.appendChild(link)
        link.click()

        // Clean up
        document.body.removeChild(link)
        const docDefinition = {
          pageOrientation: 'landscape',
          content: [
            {
              image: canvas.toDataURL(),
              width: canvas.width,
              height: canvas.height,
            },
          ],
        }
        pdfMake.createPdf(docDefinition).download('document.pdf')
      })
    },
    addPointToMap(geoJson) {
      this.$refs.map.displayCluster(geoJson, true)
      this.$refs.map.disableEditDraw()
    },
    addClusterToMap(geoJson, hover = true) {
      let filter = [
        'case',
        ['==', ['get', 'confidence'], 'l'],
        'fire-low',
        ['==', ['get', 'confidence'], 'n'],
        'fire-moderate',
        'fire-high',
      ]
      let customIcon = {
        images: [
          { src: require('@/assets/images/marker/fire-high.png'), id: 'fire-high' },
          { src: require('@/assets/images/marker/fire-moderate.png'), id: 'fire-moderate' },
          { src: require('@/assets/images/marker/fire-low.png'), id: 'fire-low' },
        ],
        filterIcon: filter,
      }
      this.$refs.map.displayCluster(geoJson, hover, customIcon)
      this.$refs.map.disableEditDraw()
    },
    displayPopup(features, point) {
      if (features[0].properties.isFire) this.displayHotpotPopup(features, point)
      else if (features[0].properties.mag) this.displayEarthquakePopup(features, point)
    },
    async displayHotpotPopup(features, point) {
      try {
        this.loading = true
        if (features[0].properties.isUpload) {
          this.createPopup(point, features[0].properties, features, 'forest_fire')
        } else {
          let res = await getDetailHotspots({
            payload: {
              source: this.$refs.hotspots.source,
              id: features[0].properties.id,
            },
          })
          this.createPopup(point, res.data, features, 'forest_fire')
        }
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async displayEarthquakePopup(features, point) {
      try {
        this.loading = true
        let res = await getDetailEarthquake({
          id: features[0].properties.id,
        })
        this.createPopup(point, res.data, features, 'earthquake')
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    createPopup(point, data, features, dataType = 'forest_fire') {
      let properties = ''
      for (const property in data) {
        if (property !== 'geojson' && property !== 'isFire' && property !== 'isUpload') {
          properties =
            properties +
            `<div style='float: left; color: black; margin-left: 10px'><b style='text-transform: capitalize'>${property}: </b>${data[property]}</div>
              <br>`
        }
      }
      let buttonHtml = ''
      if (this.currentUser.permissions.includes('tasking_order.submit')) {
        buttonHtml = `<div style='float: left; color: black; margin-left: 10px; margin-top: 12px'>
    <button
      id='task'
      style='display: flex;
      flex-direction: column;
      align-items: center;
      padding: 4px 12px;
      border-radius: 6px;
      border: none;
      color: #fff;
      background: linear-gradient(180deg, #4b91f7 0%, #367af6 100%);
      background-origin: border-box;
      box-shadow: 0px 0.5px 1.5px rgba(54, 122, 246, 0.25), inset 0px 0.8px 0px -0.25px rgba(255, 255, 255, 0.2);
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;' role='button'
    >
    Add to tasking
    </button>
  </div>`
      }
      properties = properties + buttonHtml
      let html = `<div style='width: fit-content; text-align: center;'>` + properties + `</div>`
      this.$refs.map.displayPopup(point, html, true)
      const btn = document.getElementById('task')
      btn.addEventListener('click', () => {
        this.$refs.createOrder.openDialog({
          geojson: {
            type: 'FeatureCollection',
            features: [features[0]],
          },
          dataType: dataType,
          name: `${dataType}${data.date ? '_' + data.date : ''}`,
          id: features[0].properties.id,
        })
      })
    },
    async getDataExplorer(payload = {}) {
      try {
        this.loading = true
        payload.includes = ['type']
        const res = await getDataExplorer({
          payload: payload,
          projectId: this.$route.params.id,
        })
        // this.allData = res.data.map(item => new DataExplorerModel(item))
        this.allData = res.data.map(item => {
          if (item.tile_info.type == 'aoi')
            item.tile_info.thumbnail_url = `${config.backend_pub}${item.tile_info.thumbnail_url}`
          return item
        })
      } catch (e) {
        console.warn('Error when get data explorer: ', e)
      } finally {
        this.loading = false
      }
    },
    async getAOI() {
      await this.$store.dispatch('AOI/getListAOI', { projectId: this.$route.params.id })
    },
    addGeoJsonToMap(data) {
      this.$refs.map.addGeoJsonToMap(data.name, data.geojson, 'red', data.uuid, 'line', true)
    },
    changeDisplayLayer(id, visibility = undefined) {
      let layer = this.layers.find(val => val.dataId === id)
      if (layer) this.$refs.map.changeVisibility(layer.id, visibility)
    },
    removeData(id) {
      let layer = this.layers.find(val => val.dataId === id)
      if (layer) this.$refs.map.removeLayer(layer.id)
    },
    async addThumbnailToMap(image) {
      let tmpImg = {
        display: true,
        id: image.id,
        layerType: 'Raster_Image',
        name: image.properties.acquired,
        tiles: image.properties.tile_urls,
        bounds: bbox(image.geometry),
      }
      this.searchLayers.push(tmpImg)
      this.$refs.map.addImageToMap(tmpImg, this.$refs.map.clusterId ? 'clusters' + this.$refs.map.clusterId : undefined)
      await sleep(200)
      this.$refs.map.moveDrawLayerToTop()
      this.$refs.map.moveClusterToTop()
    },
    addToMap(data) {
      if (data.tile_info.type === 'images') {
        let image = {
          id: data.name,
          display: true,
          data: data.tile_info,
          layerType: 'Raster_Image',
          name: data.name,
          tiles: [data.tile_info.tile_url],
          bounds: data.tile_info.bbox,
        }
        this.$refs.map.addImageToMap(image)
      } else {
        this.$refs.map.addVectorTiles({
          display: true,
          data: data.tile_info,
          tiles: [data.tile_info.tile_url],
          bounds: data.tile_info.bbox,
          paint: VectorStyle.getStyle(data.tile_info.styles),
          layerName: 'default',
          name: data.name,
          id: data.name,
          type: data.tile_info.styles.type,
        })
      }
      this.$store.commit('message/SHOW_SUCCESS', 'Success')
    },
    removeSearchLayer() {
      let layers = this.layers.filter(layer => this.searchLayers.some(image => image.id === layer.dataId))
      layers.forEach(layer => {
        this.$refs.map.removeLayer(layer.id)
      })
    },
  },
}
</script>

<style scoped></style>
